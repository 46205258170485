<template>
  <div>
    <v-snackbar
      v-model="successSnackbar"
      :timeout="4000"
      top
      transition="scroll-y-transition"
      color="success"
    >
      Заявка на оформления отпуска № {{result.elementId}} успешно создана!
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="successSnackbar = false"
        >
          ОК
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      top
      :timeout="-1"
      color="error"
    >
      Ошибка ({{result}}) обратитесь к администратору!!!
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="errorSnackbar = false"
        >
          ОК
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      color="error"
      v-model="isValidationError"
      top
      right
    >
      Ошибка валидации формы, проверьте поля
    </v-snackbar>
    <div class="wrapper-form">
      <form @submit.prevent>
        <div class="wrapper-block">
          <v-row>
            <v-col
              class="my-0 py-0"
              xs="12"
              md="6"
            >
              <UserInput v-model="user" :error-messages="userErrors"></UserInput>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="my-0 py-0"
              xs="12"
              md="6"
            >
              <v-menu
                v-model="menu"
                ref="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeText"
                    label="Даты отпуска"
                    outlined
                    dense
                    prepend-icon="mdi-calendar"
                    :error-messages="dateRangeTextErrors"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dates"
                  :min="new Date().toISOString().substr(0, 10)"
                  range
                  locale="ru"
                  selected-items-text = 'период'
                  first-day-of-week="1"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                  >
                    Отмена
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(dates)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-radio-group
            v-model="type"
            row
            mandatory
          >
            <template v-slot:label>
              <div>Тип отпуска</div>
            </template>
            <v-radio
              v-for="n in typeItems"
              :key="n.value"
              :label="n.label"
              :value="n.value"
            >
            </v-radio>
          </v-radio-group>
          <v-textarea
            v-model.trim="description"
            label="Комментарий (необязательно)"
            outlined
            dense
            auto-grow
            rows="3"
            @input="$v.description.$touch()"
            @blur="$v.description.$touch()"
          ></v-textarea>
          <v-file-input
            v-model="files"
            :label="type === 931 ? 'Заявление (необязательно)' : 'Cправка-вызова с места обучения'"
            :error-messages="filesError"
            outlined
            dense
            multiple
            counter
            prepend-icon="mdi-paperclip"
          >
            <template v-slot:selection="{ text }">
              <v-chip
                small
                label
                color="primary"
              >
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
          <v-btn
            class="mr-4 mb-4"
            color="primary"
            @click="submit"
            type="submit"
            :loading="loading"
          >
            Оставить заявку
          </v-btn>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import { required, requiredIf } from 'vuelidate/lib/validators'
  import UserInput from './app/UserInput'

  export default {
    name: 'VacationForm',
    components: { UserInput },
    validations: {
      user: { required },
      dateRangeText: { required },
      files: {
        required: requiredIf(function () {
          return this.type === 933
        })
      }
    },
    data: () => ({
      user: {},
      dates: [],
      menu: false,
      type: 931,
      typeItems: [
        {
          value: 931,
          label: 'основной'
        },
        {
          value: 933,
          label: 'учебный'
        }
      ],
      description: null,
      files: [],
      result: '',
      loading: false,
      isValidationError: false,
      successSnackbar: false,
      errorSnackbar: false
    }),
    computed: {
      dateRangeText () {
        if (this.dates[1]) {
          let dates = this.dates
          if (dates[0] > dates[1]) {
            dates = dates.reverse()
          }
          return `c ${dates[0].split('-').reverse().join('-')} по ${dates[1].split('-').reverse().join('-')}`
        }
        if (this.dates[0]) {
          return this.dates[0].split('-').reverse().join('-')
        }
        return []
      },
      userErrors () {
        const errors = []
        if (!this.$v.user.$dirty) return errors
        !this.$v.user.required && errors.push('Укажите сотрудника')
        return errors
      },
      dateRangeTextErrors () {
        const errors = []
        if (!this.$v.dateRangeText.$dirty) return errors
        !this.$v.dateRangeText.required && errors.push('Укажите даты отсутствия')
        return errors
      },
      filesError () {
        const errors = []
        if (!this.$v.files.$dirty) return errors
        !this.$v.files.required && errors.push('Необходимо прикрепить справку-вызов')
        return errors
      }
    },
    methods: {
      ...mapActions(['vacationRequest']),
      async submit () {
        this.$v.$touch()
        if (this.$v.$invalid) {
          this.isValidationError = true
          return false
        } else {
          this.$v.$reset()
          this.loading = true
          const formData = {
            user: this.user.id,
            dates: this.dates,
            type: this.type,
            description: this.description,
            files: this.files
          }
          const result = await this.vacationRequest(formData)
          this.loading = false
          this.result = result
          if (result.elementId) {
            this.successSnackbar = true
            this.description = null
            this.dates = this.files = []
          } else {
            this.errorSnackbar = true
          }
        }
      }
    }
  }
</script>
